@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
html {
  scroll-behavior: smooth;
}
.font-all {
  font-family: "Montserrat", sans-serif;
}
.bg-nav {
  background: rgba(203, 34, 41, 0.15);
  border-width: 1px 0px;
  border-style: solid;
  border-color: #cb2229;
}

/* Track */
::-webkit-scrollbar {
  width: 10px; /* Adjust the width as needed */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #6b7280; /* Set the color of the scrollbar */
  border-radius: 8px; /* Make it rounded */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #4b5563; /* Set a different color on hover if desired */
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f3f4f6; /* Set the color of the scrollbar track */
  border-radius: 8px; /* Make it rounded */
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background-color: #9ca3af; /* Set a different color when dragging if desired */
}


.shadow-l {
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.15));
}
.bg-layout {
  background: #ffffff;
  border: 1px solid #f8f8f8;
  box-shadow: 0px 0px 8px 3px rgba(203, 34, 41, 0.05);
  border-radius: 10px;
}
.border-a {
  border: 1px solid #738482;
}
.text-color {
  color: #263238;
}
/* z */
.scrollbar::-webkit-scrollbar {
  width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  background: rgb(0, 0, 0);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(211, 0, 0);
  border-radius: 3px;
}
.tilt-in-right-1 {
	-webkit-animation: tilt-in-right-1 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: tilt-in-right-1 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-9 3:31:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-right-1
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-right-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
            transform: rotateX(-30deg) translateX(300px) skewX(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-right-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
            transform: rotateX(-30deg) translateX(300px) skewX(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
